import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IUser } from './Users.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
export class User extends AbstractDocument<IUser> {
  readonly collections = {};
}

export class Users extends AbstractCollection<User, IUser> {
  static definitions = {
    _: {} as IUser,
  };

  static path = 'users';

  constructor(document: Wedding) {
    super(document.collection(Users.path), User);
  }

  static new<M extends typeof Users.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IUser> = {};

    if (key !== '_' && key in Users.definitions) {
      result = (result[key as keyof Omit<typeof Users.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
