import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IPhoto } from './Photos.types';

@Identifiable
@Timestampable
export class Photo extends AbstractDocument<IPhoto> {
  readonly collections = {};
}

export class Photos extends AbstractCollection<Photo, IPhoto> {
  static definitions = {
    _: {} as IPhoto,
  };

  static path = 'photos';

  constructor(document: Wedding) {
    super(document.collection(Photos.path), Photo);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
