import Cookies from 'universal-cookie';
import { v4 as uuidV4 } from 'uuid';
import { setCookie } from 'app-shared/lib/utils/set-cookie';

/**
 * Identifier for a cookie for logged-out users (to keep showing the same variants)
 */
export const BASIC_USER_ID_COOKIE = 'basic_client_id';

/**
 * This object handles basic userId for logged-out pages
 */
export const basicUserIdCookie = {
  get() {
    return userId;
  },

  set() {
    const cookies = new Cookies();
    const cookieUserId = cookies.get(BASIC_USER_ID_COOKIE);
    userId = cookieUserId || uuidV4();

    if (!cookieUserId) {
      setCookie(BASIC_USER_ID_COOKIE, userId);
    }

    return userId;
  },
};

let userId: string | undefined;
