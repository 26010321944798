import type { PartialKeys } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument } from '../../abstract/Document';
import { Countries, type Country } from '../Countries';
import type { ISupplier } from './Suppliers.types';
import { mergeDeepRight } from 'ramda';

/**
 * Country-overloaded `ISupplier`.
 */
type ISupplierRequired = PartialKeys<Required<ISupplier>, 'tags' | 'typeDetails'>;

export class Supplier extends AbstractDocument<ISupplier> {
  readonly collections = {};

  get(data: false): never;
  get(data?: true): Promise<ISupplier>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.reference.parent.parent.id !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Suppliers.getById(result.id).get(true), result);
    }

    return result;
  }
}

export class Suppliers extends AbstractCollection<Supplier, ISupplier> {
  static definitions = {
    _: {} as ISupplier,
  };

  static path = 'suppliers';

  constructor(document: Country) {
    super(document.collection(Suppliers.path), Supplier);
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result = await this.query().get(true);

    if (this.reference.parent.id !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Suppliers.all(), result);
    }

    return result as Record<string, ISupplierRequired>;
  }
}
