import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable } from '../../abstract/Document';
import { getFirestore } from '../../firebase/firestore';
import { Countries, type Country } from '../Countries';
import type { IKeyword } from './Keywords.types';
import { isIndexedDBAvailable } from '@firebase/util';
import { getDocsFromCache, loadBundle, namedQuery, type Query } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

/**
 * Country-overloaded `IKeyword`.
 */
type IKeywordRequired = Required<IKeyword>;

@Identifiable
export class Keyword extends AbstractDocument<IKeyword> {
  readonly collections = {};

  get(data: false): never;
  get(data?: true): Promise<IKeyword>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.reference.parent.parent.id !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Keywords.getById(result.id).get(true), result) as IKeyword;
    }

    return result;
  }
}

export class Keywords extends AbstractCollection<Keyword, IKeyword> {
  static definitions = {
    _: {} as IKeyword,
  };

  static path = 'keywords';

  constructor(document: Country) {
    super(document.collection(Keywords.path), Keyword);
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result: Record<string, IKeywordRequired> = {};

    const country = this.reference.parent.id;
    const queryName = `${country}-keywords`;

    try {
      const firestore = getFirestore();

      /**
       * Checks for `globalThis.indexedDB` availability, only present in the browser.
       */
      if (isIndexedDBAvailable() !== true) {
        throw new Error(`IndexedDB is not available.`);
      }

      let query = (await namedQuery(firestore, queryName)) as Query<IKeywordRequired>;

      /**
       * If the named query is not yet loaded, fetch the data bundle data from the server.
       */
      if (query == null) {
        const data = await fetch(`/api/countries/${country}/seed?v=2`);

        if (data.ok === true) {
          await loadBundle(firestore, data.body);
        }

        query = (await namedQuery(firestore, queryName)) as Query<IKeywordRequired>;

        if (query == null) {
          throw new Error(`Unable to load Firestore query '${queryName}' from local cache.`);
        }
      }

      const documents = (await getDocsFromCache(query)).docs;

      for (const document of documents) {
        result[document.id] = document.data();
      }
    } catch (error) {
      result = await this.query<IKeywordRequired>().get(true);
    }

    if (country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Keywords.all(), result) as Record<string, IKeywordRequired>;
    }

    return result;
  }

  /**
   * Get all wedding keywords grouped by category.
   */
  async getGroupedByCategory() {
    const seed = await this.all();
    const result: Record<IKeyword['category'], IKeywordRequired[]> = {};

    for (const key in seed) {
      const keyword = seed[key];

      if (result[keyword.category] === undefined) {
        result[keyword.category] = [];
      }

      result[keyword.category].push(keyword);
    }

    return result;
  }
}
