import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { Country } from '../Countries';
import { IPreset } from './Presets.types';
import { orderBy, where } from 'firebase/firestore';

@Identifiable
@Timestampable
export class Preset extends AbstractDocument<IPreset> {
  readonly collections = {};
}

export class Presets extends AbstractCollection<Preset, IPreset> {
  static definitions = {
    _: {} as IPreset,
  };

  static path = 'presets';

  constructor(document: Country) {
    super(document.collection(Presets.path), Preset);
  }

  get() {
    return this.query().get(true);
  }

  get active() {
    return this.query([where('active', '==', true), orderBy('order', 'asc')]);
  }

  async one(slug: string) {
    return this.query([where('slug', '==', slug)]).get(true);
  }
}
