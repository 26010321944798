import { translate } from '../../helpers/translate';
import type { DocumentChanged, DocumentSourced } from '../_';
import { type DocumentChangeType, type DocumentData, getDocs, onSnapshot, type Query, type QuerySnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';

export class AbstractQuery<T = DocumentData> {
  protected on: DocumentChangeType[] = null;
  protected query: Query<T> = null;

  constructor(query: Query<T>, on?: DocumentChangeType[]) {
    this.query = query;

    if (on != null) {
      this.on = on;
    }
  }

  get(data: false): Promise<Record<string, DocumentChanged<T>>>;
  get(data?: true): Promise<Record<string, T>>;

  /**
   * Returns a promise containing either typed documents or snapshots of the current query.
   *
   * @param data If `false`, returns a `DocumentChange` for each document instead.
   */
  get(data?: boolean) {
    return getDocs(this.query).then((documents) => {
      const result: Record<string, DocumentChanged<T> | T> = {};

      if (Array.isArray(this.on) === true) {
        documents.docChanges().forEach((snapshot) => {
          if (this.on.includes(snapshot.type) === true) {
            if (data === false) {
              result[snapshot.doc.id] = {
                event: snapshot.type,
                snapshot: snapshot.doc,
              };
            } else {
              result[snapshot.doc.id] = snapshot.doc.data();
            }
          }
        });
      } else {
        documents.forEach((snapshot) => {
          if (data === false) {
            result[snapshot.id] = {
              event: 'value',
              snapshot,
            };
          } else {
            result[snapshot.id] = snapshot.data();
          }
        });
      }

      return result;
    });
  }

  /**
   * Returns a promise with typed documents.
   * The most adequate translation overwrites the original content.
   *
   * @param locale The target language (BCP 47).
   */
  getTranslated(locale?: string): Promise<Record<string, T>> {
    return this.get(true).then((data) => translate(data, locale));
  }

  observe(data: false, source?: false): Observable<Record<string, DocumentChanged<T>>>;
  observe(data: false, source?: true): Observable<DocumentSourced<Record<string, DocumentChanged<T>>>>;
  observe(data?: true, source?: false): Observable<Record<string, T>>;
  observe(data?: true, source?: true): Observable<DocumentSourced<Record<string, T>>>;

  /**
   * Returns an observable containing either typed documents or snapshots of the current query.
   *
   * @param data If `false`, returns a `DocumentChange` for each document instead.
   * @param source If `true`, returns `local` if there are pending writes, or `server` otherwise.
   */
  observe(data?: boolean, source = false) {
    return new Observable((subscriber) => {
      const callback = (documents: QuerySnapshot<T>) => {
        const result: Record<string, DocumentChanged<T> | T> = {};

        if (Array.isArray(this.on) === true) {
          documents.docChanges().forEach((snapshot) => {
            if (this.on.includes(snapshot.type) === true) {
              if (data === false) {
                result[snapshot.doc.id] = {
                  event: snapshot.type,
                  snapshot: snapshot.doc,
                };
              } else {
                result[snapshot.doc.id] = snapshot.doc.data();
              }
            }
          });
        } else {
          documents.forEach((snapshot) => {
            if (data === false) {
              result[snapshot.id] = {
                event: 'value',
                snapshot,
              };
            } else {
              result[snapshot.id] = snapshot.data();
            }
          });
        }

        if (source === true) {
          return subscriber.next({
            result,
            source: documents.metadata.hasPendingWrites ? 'local' : 'server',
            fromCache: documents.metadata.fromCache,
          });
        }

        return subscriber.next(result);
      };

      return onSnapshot(this.query, callback, (error: Error) => subscriber.error(error));
    });
  }

  observeTranslated(locale?: string, source?: false): Observable<Record<string, T>>;
  observeTranslated(locale?: string, source?: true): Observable<DocumentSourced<Record<string, T>>>;

  /**
   * Returns an observable containing typed documents or snapshots of the current query.
   * The most adequate translation overwrites the original content.
   *
   * @param locale The target language (BCP 47).
   * @param source If `true`, returns `local` if there are pending writes, or `server` otherwise.
   */
  observeTranslated(locale?: string, source = false) {
    return new Observable((subscriber) => {
      const callback = (documents: QuerySnapshot<T>) => {
        const result: Record<string, DocumentChanged<T> | T> = {};

        if (Array.isArray(this.on) === true) {
          documents.docChanges().forEach((snapshot) => {
            if (this.on.includes(snapshot.type) === true) {
              result[snapshot.doc.id] = translate(snapshot.doc.data(), locale);
            }
          });
        } else {
          documents.forEach((snapshot) => {
            result[snapshot.id] = translate(snapshot.data(), locale);
          });
        }

        if (source === true) {
          return subscriber.next({
            result,
            source: documents.metadata.hasPendingWrites ? 'local' : 'server',
          });
        }

        return subscriber.next(result);
      };

      return onSnapshot(this.query, callback, (error: Error) => subscriber.error(error));
    });
  }
}
