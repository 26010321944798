import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IBadge } from './Badges.types';

@Identifiable
@Timestampable
export class Badge extends AbstractDocument<IBadge> {
  readonly collections = {};
}

export class Badges extends AbstractCollection<Badge, IBadge> {
  static definitions = {
    _: {} as IBadge,
  };

  static path = 'badges';

  constructor(document: Wedding) {
    super(document.collection(Badges.path), Badge);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  /**
   * Returns whether a badge was achieved or not.
   */
  static achieved(badge: IBadge) {
    return badge.counters.current >= badge.counters.minimum;
  }

  /**
   * Increments by 1 the `current` count of badge by id.
   */
  async increment(id: string) {
    return await this.getById(id).increment('counters.current');
  }
}
