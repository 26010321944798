import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IGuest, IGuest_Address, IGuest_Gender } from './Guests.types';
import { arrayRemove, arrayUnion, deleteField } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
export class Guest extends AbstractDocument<IGuest> {
  readonly collections = {};
}

export class Guests extends AbstractCollection<Guest, IGuest> {
  static definitions = {
    _: {} as IGuest,
    address: {} as IGuest_Address,
    gender: {} as IGuest_Gender,
  };

  static path = 'guests';

  constructor(document: Wedding) {
    super(document.collection(Guests.path), Guest);
  }

  static new<M extends typeof Guests.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IGuest> = {
      events: {
        wedding: {
          attending: null,
          invitation: '',
        },
      },
      gender: null,
      head: null,
    };

    if (key !== '_' && key in Guests.definitions) {
      result = (result[key as keyof Omit<typeof Guests.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  /**
   * Removes a guest category with a specified ID.
   */
  removeCategory(id: string) {
    return this.wedding.set({
      guests: {
        categories: {
          data: {
            [id]: deleteField(),
          },
          sort: arrayRemove(id),
        },
      },
    });
  }

  /**
   * Removes a guest list table with a specified ID.
   */
  removeTable(id: string) {
    return this.wedding.set({
      guests: {
        tables: {
          data: {
            [id]: deleteField(),
          },
          sort: arrayRemove(id),
        },
      },
    });
  }

  /**
   * Resets `Wedding.guests` and drops all documents in the guests sub-collection.
   * If the wedding already has a previous `guests.estimate`, that will be persisted.
   */
  async reset() {
    const guests = Weddings.new('_').guests;
    const previous = await this.wedding.get();

    if (previous != null) {
      if (previous.guests?.estimate != null) {
        guests.estimate = previous.guests?.estimate;
      }
    }

    return await Promise.all([this.wedding.update({ guests }), this.delete(true)]);
  }

  /**
   * @deprecated Use `reset()` instead.
   */
  resetGuestList() {
    return this.reset();
  }

  /**
   * Creates or updates a guest category with a specified ID and name.
   * If the category doesn't yet exists, a new entry is created and the category is added at the end of the sort array.
   */
  upsertCategory(id: string, name: string) {
    return this.wedding.set({
      guests: {
        categories: {
          data: {
            [id]: name,
          },
          sort: arrayUnion(id),
        },
      },
    });
  }

  /**
   * Creates or updates a guest table with a specified ID and name.
   * If the table doesn't yet exists, a new entry is created and the table is added at the end of the sort array.
   */
  upsertTable(id: string, name: string) {
    return this.wedding.set({
      guests: {
        tables: {
          data: {
            [id]: name,
          },
          sort: arrayUnion(id),
        },
      },
    });
  }
}
