// Fork of https://github.com/appannie/ab-testing (MIT)

/**

 {
 // The version isn't used right now, it is used to allow potentially breaking configuration
 // change in the future. Current version is "1.0"
 version: "1.0",

 experiments: [
 {
 // Unique name used to refer to an experiment; used within `useCohortOf`
 name: 'experiment-name',
 cohorts: [
 {
 // Name of a cohort. These are the values returned by `useCohortOf`
 name: 'blue',

 // The force_include section is used to force users into given cohorts if their
 // userProfile key. In many keys are used, *any* match will force include the user in
 // the cohort.
 // The force_include rules are checked in the order of the cohorts in the array and
 // the first match wins.
 // This section is optional.
 force_include: {
 persona: [
 'data analyst'
 ]
 },

 // The users are allocated to values in a range of 0 to 100. The allocation config
 // represents the slice of users allocated to a cohort. You can have multiple
 // allocation ranges.
 // Every range needs to be unique and not overlap other ranges in any other cohort.
 allocation: [
 [0, 25]
 ],
 // The allocation_criteria key allows us to further filter the set of users by enforcing
 // a criteria that must be valid before the cohort is approved. In this case,
 // the user must have an email domain of data.ai or appannie.com.
 allocation_criteria: {
 email_domain: ['appannie.com', 'data.ai']
 }
 },
 {
 // "control" is the default cohort. All experiments always have a control cohort.
 // All users not allocated to other cohorts will be assigned to "control" by default.
 name: 'control'
 },
 ]
 }
 ]
 }
 */
import crc from 'crc';

const crc32 = crc.crc32;

type UserProfile = { [s: string]: string };

type ForceInclude = {
  [key: string]: string[];
};

export const MAX_COHORTS = 8;
export type CohortNames = 'control' | '1' | '2' | '3' | '4' | '5' | '6' | '7';

/**
 * This is a type for variants configuration for the A/B testing library,
 * which is slightly different from the one in Firestore
 * (due to the limitations of Firestore for storing array of arrays in `allocation` field)
 */
export type Cohort = {
  name: CohortNames;
  allocation?: [number, number][];
  force_include?: ForceInclude;
  allocation_criteria?: ForceInclude;
};

export type Experiment = {
  id: string;
  name: string;
  cohorts: Cohort[];
};

export type ABTestingConfig = {
  salt: string;
  version: '1.0';
  experiments: Experiment[];
};

function getModuloValue(experiment: string, userId: number | string): number {
  return crc32(String(userId), crc32(experiment)) % 100;
}

function validateCriteria(criteria: ForceInclude, userProfile: UserProfile): boolean {
  for (const key in criteria) {
    if (!criteria[key].includes(userProfile[key])) {
      return false;
    }
  }

  return true;
}

export function validateAllocation(
  cohort: Cohort,
  userProfile: UserProfile,
  userSegmentNum: number,
): boolean {
  let withinRange = false;
  let fulfillsCriteria = true;
  for (const allocation of cohort.allocation || []) {
    withinRange = allocation[0] <= userSegmentNum && userSegmentNum < allocation[1];

    if (withinRange) {
      break;
    }
  }

  if (withinRange && cohort.allocation_criteria) {
    fulfillsCriteria = validateCriteria(cohort.allocation_criteria, userProfile);
  }

  return withinRange && fulfillsCriteria;
}

function validateForceInclude(cohort: Cohort, userProfile: UserProfile): boolean {
  if (cohort.force_include) {
    for (const key in cohort.force_include) {
      if (cohort.force_include[key].includes(userProfile[key])) {
        return true;
      }
    }
  }

  return false;
}

function matchUserCohort(
  experimentConfig: Experiment,
  userId: number | string,
  userProfile: { [s: string]: string },
): string {
  let allocatedCohort = 'control';
  const userSegmentNum = getModuloValue(experimentConfig.name, userId);

  for (const cohort of experimentConfig.cohorts) {
    if (validateForceInclude(cohort, userProfile)) {
      return cohort.name;
    }

    if (allocatedCohort === 'control' && cohort.allocation) {
      if (validateAllocation(cohort, userProfile, userSegmentNum)) {
        allocatedCohort = cohort.name;
      }
    }
  }

  return allocatedCohort;
}

export class Experiments {
  config: { [experimentName: string]: Experiment };
  userId: number | string;
  userProfile: { [s: string]: string };
  matchedCohorts: { [experimentName: string]: string };

  constructor(
    config: ABTestingConfig,
    userId: number | string,
    userProfile: { [s: string]: string },
  ) {
    this.config = {};
    this.userId = userId;
    this.userProfile = userProfile;
    this.matchedCohorts = {};
    for (const experimentConfig of config.experiments) {
      // Changes to the original library:
      // Replaced identifying via name with identifying via id
      // @chris
      this.config[experimentConfig.id] = experimentConfig;
    }
  }

  getCohort = (experimentName: string): string => {
    if (!(experimentName in this.matchedCohorts)) {
      const experimentConfig: Experiment = this.config[experimentName];
      if (experimentConfig == null) {
        process.env.NODE_ENV !== 'test' &&
          console.error(`unrecognized ab testing experiment name: ${experimentName}`);
        this.matchedCohorts[experimentName] = 'control';
      } else {
        this.matchedCohorts[experimentName] = matchUserCohort(
          experimentConfig,
          this.userId,
          this.userProfile,
        );
      }
    }
    return this.matchedCohorts[experimentName];
  };

  hasExperiment = (experimentName: string): boolean => !!this.config[experimentName];
}

export default Experiments;
